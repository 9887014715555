<template>
  <div class="heart-word">
    <!-- <van-nav-bar title="公开信详情" left-arrow @click-left="onClickLeft" /> -->
    <div class="heart-word-body">
      <ul class="heart-word-list">
        <li>
          <div class="header">
            <div class="img">
              <img :src="detailData.avatar" alt />
            </div>
            <div class="user-info">
              <h3>{{detailData.realName}}</h3>
              <p>{{detailData.deptName}}</p>
            </div>
          </div>
          <div class="content">
            <p>{{detailData.sendMessage}}</p>
            <div class="img-list" v-if="detailData.picture">
              <div v-for="(itm,ind) in pic" :key="ind">
                <img :src="itm" alt />
              </div>
            </div>
          </div>
          <div class="like">
            <div class="see">
              <van-icon name="eye-o" class="icon-yanjing" />
              <span class="see-num">{{detailData.browseNum}}</span>
            </div>
            <p>{{detailData.createTime}}</p>
          </div>
        </li>
      </ul>
      <div class="comment" v-if="commentList.length!==0">
        <h4>
          评论(
          <span>{{total}}</span>)
        </h4>
        <ul>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :offset="10"
          >
            <li v-for="(item,index) in commentList" :key="index">
              <div class="img">
                <img :src="item.avatar" alt />
              </div>
              <div class="people">
                <h5>
                  <span>{{item.realName}}</span>
                  <span class="time">{{item.commentTime}}</span>
                </h5>
                <p>{{item.comment}}</p>
              </div>
            </li>
          </van-list>
        </ul>
      </div>
    </div>
    <div class="post-myword" v-if="detailData.myFlag!==1">
      <van-field
        class="input"
        type="input"
        rows="3"
        autosize
        name
        id="myword"
        placeholder="我来说一说"
        v-model="comment"
      />
      <van-button round type="danger" @click="postComment" class="searchBtn">发送</van-button>
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import { toDetail, requestCommentList, sendComment } from '@/api/heartWord';
export default {
  data() {
    return {
      detailData: {},
      commentList: [
        // {
        //   user: '用户名',
        //   time: '03-31 14:00:43',
        //   content: '不忘初心牢记使命不忘初心不忘初心牢记使命不忘初心'
        // }
      ],
      total: 0,
      comment: '',
      currentPage: 1,
      loading: false,
      finished: false,
      getRecordStatus: false
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //详情数据
    getDetail() {
      toDetail({
        id: this.$route.params.id
      })
        .then(res => {
          if (res.success) {
            this.detailData = res.data;
            this.getCommentList();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //请求评论列表
    getCommentList() {
      this.getRecordStatus = true;
      requestCommentList({
        innermostId: this.$route.params.id,
        size: 6,
        current: this.currentPage
      })
        .then(res => {
          if (res.success) {
            this.currentPage++;
            // this.commentList = res.data.records;
            //加载完成
            if (res.data.records.length === 0) {
              this.finished = true;
            }
            this.total = res.data.total;
            this.loading = false;
            let arr = [...this.commentList, ...res.data.records];
            this.commentList = arr;
          }
          this.getRecordStatus = false;
        })
        .catch(err => {
          console.log(err);
          this.getRecordStatus = false;
        });
    },
    //发表评论
    postComment() {
      if (!this.comment) {
        Notify({ type: 'success', message: '评论成功' });
        return;
      }
      sendComment({
        innermostId: this.$route.params.id,
        comment: this.comment
      })
        .then(res => {
          if (res.success) {
            console.log(res);
            this.currentPage = 1;
            this.loading = false;
            this.finished = false;
            this.commentList = [];
            console.log('评论后加载');
            this.getCommentList();
            this.comment = '';
            Notify({ type: 'success', message: '评论成功' });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //加载更多数据
    onLoad() {
      console.log('控件加载');
      if (!this.getRecordStatus) {
        setTimeout(() => {
          this.getCommentList();
          this.loading = true;
        }, 500);
      }
    }
  },
  computed: {
    pic() {
      return (this.detailData.picture || '').split(',');
    }
  },
  created() {
    this.getDetail();
  }
};
</script>
<style scoped lang="scss">
// 修改vant样式：>>>针对css, /deep/针对scss和less
$px: 1rem/75;
.heart-word {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .heart-word-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .heart-word-list {
      background: #fff;
      li {
        padding: 20 * $px;
        font-size: 28 * $px;
        color: #555;
        width: 750 * $px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8 * $px;
        box-sizing: border-box;
        .header {
          display: flex;
          border-bottom: 2 * $px solid #f3f3f3;
          padding-bottom: 18 * $px;
          .img {
            width: 80 * $px;
            height: 80 * $px;
            background: url('./../../assets/img/avatar.png');
            background-size: cover;
            border-radius: 50%;
            margin-right: 18 * $px;
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
          .user-info {
            h3 {
              font-size: 32 * $px;
              color: #222;
              margin-bottom: 6 * $px;
            }
            p {
              width: 550 * $px;
              font-size: 24 * $px;
              color: #999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .content {
          p {
            margin: 18 * $px 0;
            width: 100%;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 3;
            // -webkit-box-orient: vertical;
          }
          .img-list {
            display: flex;
            justify-content: space-between;
            & > div {
              background: url('./../../assets/img/list-compre.png') center;
              width: 200 * $px;
              height: 200 * $px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .like {
          display: flex;
          justify-content: space-between;
          margin-top: 18 * $px;
          font-size: 22 * $px;
          .see {
            color: #555;
            .icon-yanjing {
              font-size: 32 * $px;
              margin-right: 5 * $px;
              vertical-align: bottom;
            }
            .see-num {
              font-size: 24 * $px;
            }
          }
          p {
            color: #999;
          }
        }
      }
    }
    .comment {
      margin-top: 20 * $px;
      background: #fff;
      h4 {
        font-size: 28 * $px;
        color: #222;
        font-weight: 600;
        padding: 15 * $px;
      }
      & > ul {
        font-size: 28 * $px;
        padding: 0 20 * $px;
        li {
          display: flex;
          padding: 20 * $px 0;
          height: 100%;
          border-bottom: 1 * $px solid #f3f3f3;
          .img {
            width: 66 * $px;
            height: 66 * $px;
            background: url('./../../assets/img/avatar.png');
            background-size: cover;
            margin-right: 10 * $px;
            border-radius: 50%;
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
          .people {
            flex: 1;
            h5 {
              display: flex;
              width: 100%;
              justify-content: space-between;
              .time {
                color: #999;
              }
            }
            & > p {
              margin: 10 * $px 0;
              width: 634 * $px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .post-myword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88 * $px;
    .input {
      width: 710 * $px;
      height: 60 * $px;
      line-height: 60 * $px;
      border: 0;
      background: rgba(245, 245, 245, 1);
      border-radius: 30 * $px;
      font-size: 32 * $px;
      padding: 0 10 * $px;
    }
    .searchBtn {
      width: 150 * $px;
      height: 60 * $px;
      margin: 0 10 * $px;
      line-height: 60 * $px;
    }
  }
}
</style>
